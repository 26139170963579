import React, { FunctionComponent } from "react";

import { ImageInterface } from "../../types/SanityTypes";
import { QuoteBlockInterface } from "../QuoteBlock";
import { TextEditorBlockInterface } from "../TextEditorBlock";

import SanityImage from "gatsby-plugin-sanity-image";
import classNames from "classnames";

import Section from "../Section";
import VerticalSliderBlock from "../VerticalSliderBlock";

import useMediaQuery from "../../hooks/useMediaQuery";

import "./styles.scss";
import BlockRenderer from "../BlockRenderer";
import { useInView } from "react-intersection-observer";
import { IMAGE_SIZES } from "../../constants";

type ThemeProps = {
  bgColor: {
    title: string;
    value: string;
  };
};

export type SlideProps = QuoteBlockInterface | TextEditorBlockInterface;

interface VerticalCarouselInterface {
  slides: SlideProps[];
  theme: ThemeProps;
  sanityImage: ImageInterface;
  imageAlignment: string;
}

const VerticalSlider: FunctionComponent<VerticalCarouselInterface> = props => {
  const { theme, slides, sanityImage, imageAlignment } = props;
  const bgClassName = theme?.bgColor?.title || "default";
  const matches = useMediaQuery("(max-width: 768px)");
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "5px 0px"
  });

  const image = (
    <SanityImage
      {...sanityImage}
      width={644}
      height={515}
      htmlWidth={644}
      htmlHeight={515}
      className="img-fluid c-vertical-carousel__image c-vertical-carousel__image--rounded"
      sizes={IMAGE_SIZES.HALF}
    />
  );

  return (
    <Section sectionClassName={classNames("c-vertical-carousel", `${bgClassName.toLowerCase()}--theme`)}>
      {matches ? (
        <div className="grid-row">
          <div className="grid-col-xs-12">
            <BlockRenderer section={slides[0]} />
          </div>
          <div className="grid-col-xs-12">{image}</div>
          <div className="grid-col-xs-12">
            <BlockRenderer section={slides[1]} />
          </div>
        </div>
      ) : (
        <div className="grid-row" ref={ref}>
          <div className={classNames("grid-col-md-6", imageAlignment !== "left" && "order-1")}>{image}</div>
          <div className="grid-col-md-6 c-vertical-carousel__slide">
            <VerticalSliderBlock slides={slides} mouseWheel={inView ? true : false} />
          </div>
        </div>
      )}
    </Section>
  );
};

export default VerticalSlider;
