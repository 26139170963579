import React, { FunctionComponent, useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper";
import { SlideProps } from "../VerticalCarouselBlock";
import BlockRenderer from "../BlockRenderer";

interface VerticalSliderBlockInterface {
  slides: SlideProps[];
  mouseWheel?: boolean;
}

const VerticalSliderBlock: FunctionComponent<VerticalSliderBlockInterface> = ({ slides, mouseWheel }) => {
  const [mouseWheelState, setMouseWheelState] = useState(false);
  useEffect(() => setMouseWheelState(mouseWheel), [mouseWheel]);
  return (
    <div key={mouseWheelState}>
      <Swiper
        direction={"vertical"}
        pagination={{
          clickable: true
        }}
        effect={"fade"}
        mousewheel={mouseWheelState}
        modules={[Pagination, Mousewheel]}
        className="mySwiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <BlockRenderer section={slide} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default VerticalSliderBlock;
